import React, { useState } from 'react';
import { getCookie, setCookie } from 'typescript-cookie'
import moment from 'moment';
import { Outlet, Navigate, useLocation, useNavigate } from 'react-router-dom';
import { SessionManager } from '../Services';
import { startsWith } from 'lodash';

import { Header, PageContainer, Card, Nav, Icons, Filters, Modal } from '../Components';

interface FilterProps {
  endDate: moment.Moment;
  setEndDate: (date: moment.Moment) => void;
  startDate: moment.Moment;
  setStartDate: (date: moment.Moment) => void;
  location: LocationType;
  setLocation: (location: LocationType) => void;
}

// use Filters when design can fit them in
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const FiltersModal = (props: FilterProps) => (
  <Modal
    Trigger={(onClick) => <Icons.Filter className="align-self-center" variant="secondary" onClick={onClick} />}
  >
    {({ hide }) => <Filters {...props} onSubmit={hide} />}
  </Modal>
)

const useContext = () => {
  (getCookie('filterStartDate') && moment(getCookie('filterStartDate')) > moment()) || setCookie('filterStartDate', moment().startOf('day').format());
  (getCookie('filterEndDate') && moment(getCookie('filterEndDate')) > moment()) || setCookie('filterEndDate', moment().endOf('day').format());
  getCookie('filterLocation') || setCookie('filterLocation', 'Brisbane');

  const [startDate, setStartDate] = useState<moment.Moment>(moment(getCookie('filterStartDate')))
  const [endDate, setEndDate] = useState<moment.Moment>(moment(getCookie('filterEndDate')))
  const [location, setLocation] = useState<LocationType>(getCookie('filterLocation') as LocationType)

  return {
    endDate,
    location,
    startDate,
    setEndDate: (val: moment.Moment) => {
      setEndDate(val);
      setCookie('filterEndDate', val.format())
    },
    setLocation: (val: LocationType) => {
      setLocation(val);
      setCookie('filterLocation', val)
    },
    setStartDate: (val: moment.Moment) => {
      setStartDate(val);
      setCookie('filterStartDate', val.format())
    }
  }
}

const AuthedLayout: React.FC = () => {
  const browserLocation = useLocation();
  const navigate = useNavigate();

  const logout = () => {
    navigate('/login')
    SessionManager.delete();
  };

  if(!SessionManager.get().session) {
    return <Navigate to='/login' state={{ from: browserLocation }} replace />
  }

  const navItems= [
    {
      href: '/scan',
      name: 'Scan',
      active: startsWith(browserLocation.pathname, '/scan') || browserLocation.pathname === '/',
      disabled: false
    },
    {
      href: '/runs',
      name: 'Review',
      active: startsWith(browserLocation.pathname, '/runs'),
      disabled: false
    }
  ]
  
  const ctx = useContext();

  return(
      <PageContainer>
        <Header logout={logout} />
        <Card className="pt-2" variant='white'>
          <>
            <div className='d-flex justify-content-between'>
              <Nav links={navItems}/>
              <FiltersModal {...ctx} />
            </div>
            <Outlet context={ctx}/>
          </>
        </Card>
      </PageContainer>
  );
}

export default AuthedLayout;
